<template>
  <div class="addArticle">
    <div class="title">
      <span>运营管理 / </span> <span>科普文章 / </span>
      <span class="black">{{ edit ? "编辑" : "新增" }} </span>
    </div>
    <el-card>
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-row :gutter="24">
          <el-col :xs="24" :md="14">
            <el-form-item label="所属板块：" prop="adaptorType">
              <el-select class="w_100" v-model="addForm.adaptorType" placeholder="请选择科普所属板块" @change="getThemetypeList">
                <el-option v-for="(item, index) in blackList" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="科普文章分类：" prop="typeId" v-if="addForm.adaptorType">
              <el-select class="w_100" v-model="addForm.typeId" placeholder="请选择科普文章分类">
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.typeName" :value="item.typeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="科普文章标题：" prop="title">
              <el-input v-model="addForm.title" placeholder="请输入科普文章标题"></el-input>
            </el-form-item>
            <el-form-item label="作者：" prop="author">
              <el-input v-model="addForm.author" placeholder="请输入作者"></el-input>
            </el-form-item>
            <el-form-item label="科普简介：" prop="summary">
              <el-input type="textarea" placeholder="选填，摘要会在订阅号消息、转发链接等文章外的场景显露，帮助读者快速了解内容，如不填写则默认为摘取正文前54字"
                v-model="addForm.summary" maxlength="120" :rows="4" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="10">
            <el-form-item label="封面图片：" prop="coverUrl">
              <div class="flex">
                <el-upload :action="action1" accept=".png,.jpg,.jpeg,.bmp,.webp" :headers="headers"
                  :show-file-list="false" :on-success="handleAvatarSuccess">
                  <el-button size="small" type="primary">选择资源文件</el-button>
                </el-upload>
                <span class="upload_span_text">建议上传尺寸121pt*92pt，仅支持png/jpg/jpeg/bmp/webp格式</span>
              </div>
              <div>
                <img v-if="addForm.coverUrl" :src="addForm.coverUrl" class="img_240" />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="文章内容：" prop="htmlPage">
          <TinyMceEditor v-model="addForm.htmlPage" @change="changeTinyContent" />
        </el-form-item>
        <el-form-item class="text_center">
          <!-- state 0上架  2待上架 -->
          <el-button type="primary" size="small" @click="submit('0')">上线</el-button>
          <el-button type="primary" size="small" plain @click="submit('2')">保存待投放</el-button>
          <el-button size="small" @click="cancel()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import TinyMceEditor from "@/components/TinyMceEditor";
  import {
    topicTypes,
    files1,
    addPsy,
    updatePsy,
    psyInfo,
  } from "@/api/api";
  import ossUpload from "@/components/ossUpload"
  import qiniuyun from "@/components/qiniuyun"
import { Container } from 'element-ui';
  export default {
    components: {
      TinyMceEditor,
      ossUpload,
      qiniuyun
    },
    data() {
      return {
        addForm: {
          typeId:"",
          typeTheme: 1,
          title: "",
          author: "",
          summary: "",
          coverUrl: "",
          htmlPage: "",
          status:"",
          adaptorType:""
        },
        blackList:[{
          id:3,
          name:'学生科普'
        },{
          id:4,
          name:'家长科普'
        }],
        rules: {
          adaptorType: [{
            required: true,
            message: "请选择所属板块"
          }],
          typeId: [{
            required: true,
            message: "请选择科普文章分类"
          }],
          title: [{
            required: true,
            message: "请输入科普文章标题"
          }],
          author: [{
            required: true,
            message: "请输入作者"
          }],
          coverUrl: [{
            required: true,
            message: "请上传封面图片"
          }],
          htmlPage: [{
            required: true,
            message: "请输入文章内容"
          }],
        },
        typeList: [],
        action1: files1.url,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: this.$util.getToken("access_token"),
         
        },
        edit: "",
      };
    },
    computed: {
      previousForm() {
        return this.$store.state.previousForm;
      },
      uploadStyle() {
        return this.$store.state.uploadStyle;
      },
    },
    created() {
      if (this.$route.params.id != "true") {
        this.edit = true;
        this.getInfo(this.$route.params.id);
     

      }
      if (this.$route.params.form) {
        this.$store.commit("setParam", {
          previousForm: this.$route.params.form
        });
      }
    },
    methods: {
      changeTinyContent(val) {
        this.addForm.htmlPage = val
      },
      // 查看详情
      getInfo(val) {
        this.$request(psyInfo,{id:val}).then((res) => {
          if (res.code === 0) {
            this.addForm = res.data;
            this.addForm.adaptorType = res.data.adaptorType
            this.getThemetypeList(this.addForm.adaptorType,1)
          }
        });
      },
      // 上传封面图
      handleAvatarSuccess(res, file) {
        if (res.code == 0) {
          this.$util.message("suc", "上传成功");
          this.addForm.coverUrl = res.data.url;
          this.$refs.addForm.validate((result) => {
            return true
          })
        } else {
          this.$util.message("err", res.msg);
        }
      },
      // 获取所属类型列表
      getThemetypeList(e,bool) {
        if(!bool){
           this.addForm.typeId = ""
        }
        this.$request(topicTypes,{typeTheme:1,adaptorType:e}).then((res) => {
          if (res.code === 0) {
            this.typeList = res.data;
          }
        });
      },
      submit(val) {
        let url = this.edit ? updatePsy : addPsy;
        this.addForm.status = val;
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.$request(url, this.addForm).then((res) => {
              if (res.code === 0) {
                this.$util.message("suc", "操作成功");
                if (this.edit) {
                  this.getInfo(this.$route.params.id);
                }
                this.$router.push({
                  name: "science",
                  params: {
                    form: this.previousForm
                  }
                });
              }
            });
          }
        });
      },
      cancel() {
        this.$router.push({
          name: "science",
          params: {
            form: this.previousForm
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .addArticle {
    .img_240 {
      width: 50%;
      min-width: 150px;
      max-width: 240px;
      height: 180px;
      border: 1px solid #eeeeee;
    }

    .w_104 {
      width: 104px;
    }

    /deep/.el-textarea__inner {
      padding: 5px 38px 5px 15px;
    }
  }
</style>